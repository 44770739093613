export class Mail {
    id?: string;
    campaignId: string;
    emailTemplateId: string;
    subject: string;
    contentUrl: string;
    attachmentUrl: string;
    language: string;
    attachmentFilename?: string;
    uuid?: string;

    constructor(
        id: string = null,
        campaignId: string,
        emailTemplateId: string,
        subject: string,
        contentUrl: string,
        attachmentUrl: string,
        language: string,
        attachmentFilename = ""
    ) {
        this.id = id;
        this.campaignId = campaignId;
        this.emailTemplateId = emailTemplateId;
        this.subject = subject;
        this.contentUrl = contentUrl;
        this.attachmentUrl = attachmentUrl;
        this.language = language;
        this.attachmentFilename = attachmentFilename;
    }
}
