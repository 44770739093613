import {Component, EventEmitter, Input, Output} from "@angular/core";

@Component({
    selector: "app-big-switch",
    templateUrl: "./big-switch.component.html",
    styleUrls: ["./big-switch.component.scss"],
})
export class BigSwitchComponent {
    @Input() isChecked: boolean;
    @Input() isDisabled?: boolean;
    @Output() output: EventEmitter<boolean> = new EventEmitter();

    constructor() { }

    toggle() {
        this.isChecked = !this.isChecked;
        this.output.next(this.isChecked);
    }
}
