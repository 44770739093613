import {Component, OnInit} from "@angular/core";
import {LocalStorageKeys} from "@models/LocalStorageKeys";

@Component({
    selector: "upcoming-release-banner",
    templateUrl: "upcoming-release-banner.component.html",
    styleUrls: ["./upcoming-release-banner.component.scss"],
})

export class UpcomingReleaseBannerComponent implements OnInit {
    private now: Date;
    releaseDate: Date;
    showBanner: boolean;
    expired = false;
    alreadyOpened = false;

    constructor() {
    }

    ngOnInit() {
        this.now = new Date();
        // todo: make it configurable
        this.releaseDate = this.parseDate("10/01/2024");
        this.expired = this.now > this.releaseDate && this.now > this.addDays(this.releaseDate, 6);
        this.alreadyOpened = !!localStorage.getItem(LocalStorageKeys.UPCOMING_RELEASE_ALERT);
        this.showBanner = !this.expired && !this.alreadyOpened;
    }

    getReleaseDateMessage(): string {
        if (this.now.toDateString() === this.releaseDate.toDateString() || (this.now > this.releaseDate && this.now < this.addDays(this.releaseDate, 6))) {
            return `You are using a brand new version of the Brame platform, released on ${this.formatDateWithWeekday(
                this.releaseDate
            )}!`;
        }

        if (this.expired) {
            return "";
        }

        return `Brame gets better every month! We are about to deploy a new release on
            ${this.formatDateWithWeekday(this.releaseDate)} between 15:00 and 17:00 CET. Just heads up!`;
    }

    formatDateWithWeekday(date: Date): string {
        const options = {weekday: "long", year: "numeric", month: "long", day: "numeric"};
        return date.toLocaleDateString("en-US", options);
    }

    parseDate(dateString: string): Date {
        const parts = dateString.split("/");
        return new Date(parseInt(parts[2], 10), parseInt(parts[1], 10) - 1, parseInt(parts[0], 10));
    }

    addDays(date: Date, days: number): Date {
        const result = new Date(date);
        result.setDate(date.getDate() + days);
        return result;
    }

    closeBanner() {
        this.showBanner = false;
        localStorage.setItem(LocalStorageKeys.UPCOMING_RELEASE_ALERT, "true");
    }
}
