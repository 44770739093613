export enum CampaignType {
    MEMORY = "memory-game",
    QUIZ = "quiz",
    DOPE_OR_NOPE = "dope-or-nope",
    GUESS_THE_PICTURE = "guess-the-picture",
    PERSONALITY_TEST = "personality-test",
    ADVENT_CALENDAR = "advent-calendar",
    MONTHLY_CALENDAR = "monthly-calendar",
    EASTER_CALENDAR = "easter-calendar",
    SPIN_THE_WHEEL = "spin-the-wheel",
    ADVANCED_SPIN_THE_WHEEL = "advanced-spin-the-wheel",
    SURVEY = "survey",
    WEEKLY_CALENDAR = "weekly-calendar",
    SCRATCH_CARD = "scratch-card",
    PENNY_SPIN_THE_WHEEL = "penny-spin-the-wheel",
    PENNY_PATH = "penny-path",
    PENNY_SLOT_MACHINE = "penny-slot-machine",
    BIPA_SPIN_THE_WHEEL = "bipa-spin-the-wheel",
}

export enum LeadSearchCampaignType {
    MEMORY = "memory-game",
    SURVEY = "survey",
    SCRATCH_CARD = "scratch-card",
    PENNY_SPIN_THE_WHEEL = "penny-spin-the-wheel",
    PENNY_SLOT_MACHINE = "penny-slot-machine",
}

export type PennyCampaign = CampaignType.PENNY_SPIN_THE_WHEEL | CampaignType.PENNY_SLOT_MACHINE | CampaignType.PENNY_PATH;

export class CampaignTypeMetadata {
    id: CampaignType;
    displayName: string;
    icon: string;
    thumbnail: string;
    mailDomain?: string;
    modeId?: string;
    modes?: CampaignTypeMetadata[];
}

export type DefaultCampaignEmailDomain = {
    [key in CampaignType]: string;
};

export type DefaultPennyCampaignEmail = {
    [key in PennyCampaign]: { [key: string]: string };
};
