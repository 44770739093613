import { CampaignLanguage } from "@models/CampaignLanguage";
import { Asset } from "@builder-models/Asset";
import { environment } from "src/environments/environment";

const PENNY_RO_TEMPLATE_ID = "penny_stw_ro";
const PENNY_AT_TEMPLATE_ID = "penny_stw_at";

export interface PennySpinTheWheelSectorsCount {
    [key: number]: number;
}

export interface PennySpinTheWheelGameData {
    campaignId: string;
    noWinIcon: string;
    wheelSpeed: number;
    idleSpinningSpeed: number;
    multiRegistration: boolean;
    playsPerDay: number;
    playLimitEnabled?: boolean;
    numberOfIpPlaysLimit?: number;
    playMinutesLimit?: number;
    showWinPageAfter: number;
    surveyEnabled: boolean;
    showSurveyAfter: number;
    showIncompleteSurveyAfter: number;
    showConfettiSnow: boolean;
    showConfettiFirework: boolean;
    language?: string;
    unknownCustomersSettings?: { playsPerDay: number };
    countryCode?: string;
    prizeWalletEnabled?: boolean;
    sectorsCount?: PennySpinTheWheelSectorsCount;
    isNewsletterSubscriptionEnabled?: boolean;
    disablePrizeRedemptionEmails?: boolean;
    showAdAfterWin?: boolean;
}

export class PennySpinTheWheelGameDataFactory {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    static getInitial(campaignId: string, languages: CampaignLanguage[], templateConfig: any, assets: Asset[]): PennySpinTheWheelGameData {
        const gameData: PennySpinTheWheelGameData = {
            campaignId,
            noWinIcon: PennySpinTheWheelGameDataFactory.getNoWinIcon(campaignId, assets),
            wheelSpeed: 1,
            idleSpinningSpeed: 0,
            multiRegistration: false,
            playsPerDay: 1,
            playLimitEnabled: true,
            numberOfIpPlaysLimit: 20,
            playMinutesLimit: 5,
            showWinPageAfter: 2,
            surveyEnabled: true,
            showSurveyAfter: 4,
            showIncompleteSurveyAfter: 7,
            showConfettiSnow: false,
            showConfettiFirework: false,
            prizeWalletEnabled: false,
            isNewsletterSubscriptionEnabled: false,
            disablePrizeRedemptionEmails: false,
            showAdAfterWin: false,
        };

        if ([PENNY_RO_TEMPLATE_ID, PENNY_AT_TEMPLATE_ID].includes(templateConfig.templateId)) {
            gameData.unknownCustomersSettings = {
                playsPerDay: 1,
            };

            gameData.countryCode = templateConfig.templateId === PENNY_RO_TEMPLATE_ID
                ? "ro"
                : "at";
        }

        if (templateConfig.templateId === "penny_stw_hu") {
            gameData.countryCode = "hu";
        } else if (templateConfig.templateId === "penny_stw_cz") {
            gameData.countryCode = "cz";
        }

        return gameData;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    static getNoWinIcon(campaignId: string, assets: Asset[]) {
        // TODO: extract from assets once it is available
        return `${environment.campaignPreviewBaseUrl}/${campaignId}/assets/images/awards/img-prize-0.png`;
    }
}
