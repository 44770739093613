import { HttpErrorResponse } from "@angular/common/http";

export class ErrorMessage {
    text: string;

    constructor(error: string | HttpErrorResponse) {
        if (this.isString(error)) {
            this.text = error as string;
        } else {
            this.text = (error as HttpErrorResponse).message;
        }
    }

    private isString(error: string | HttpErrorResponse) {
        return typeof error === "string" || error instanceof String;
    }
}
