import { environment } from "src/environments/environment";
import { Asset } from "@builder-models/Asset";

export interface AdvancedSpinTheWheelSectorsCount {
    [key: number]: number;
}

export interface AdvancedSpinTheWheelGameData {
    sectorsCount?: AdvancedSpinTheWheelSectorsCount;
    campaignId: string;
    noWinIcon: string;
    wheelSpeed: number;
    idleSpinningSpeed: number;
    multiRegistration: boolean;
    playsPerDay: number;
    showWinPageAfter: number;
    showConfettiFirework: boolean;
    showConfettiSnow: boolean;
    poolIds: number[];
}

export class AdvancedSpinTheWheelGameDataFactory {
    static getInitial(
        campaignId: string,
        assets: Asset[]
    ): AdvancedSpinTheWheelGameData {
        return {
            campaignId,
            noWinIcon: AdvancedSpinTheWheelGameDataFactory.getNoWinIcon(
                campaignId,
                assets
            ),
            wheelSpeed: 1,
            idleSpinningSpeed: 0,
            multiRegistration: true,
            playsPerDay: 10000,
            showWinPageAfter: 2,
            showConfettiFirework: false,
            showConfettiSnow: false,
            poolIds: [0],
        };
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    static getNoWinIcon(campaignId: string, assets: Asset[]) {
        // TODO: extract from assets once it is available
        return `${environment.campaignDataStorage}/${campaignId}/assets/images/awards/img-prize-0.png`;
    }
}
