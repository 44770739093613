import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { NgxDropzoneModule } from "ngx-dropzone";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ChartsModule } from "angular-bootstrap-md";
import { ComponentsModule } from "./components/components.module";
import { ModalModule } from "./modal/modal.module";
import { DirectivesModule } from "./directives/directives.module";

const modules = [
    // angular
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,

    // thrid party
    NgxDropzoneModule,
    NgbModule, // bootstrap
    ChartsModule,

    // our stuff
    ComponentsModule,
    ModalModule,
    DirectivesModule,
];

@NgModule({
    imports: modules,
    exports: modules,
})
export class SharedModule { }
