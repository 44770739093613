import { CampaignLanguage } from "@models/CampaignLanguage";
import { Asset } from "@builder-models/Asset";

export interface PennySlotMachineGameData {
    campaignId: string;
    speed: number;
    multiRegistration: boolean;
    playsPerDay: number;
    playLimitEnabled: boolean;
    numberOfIpPlaysLimit: number;
    playMinutesLimit: number;
    showWinPageAfter: number;
    surveyEnabled: boolean;
    showSurveyAfter: number;
    showIncompleteSurveyAfter: number;
    language?: string;
    showConfettiSnow: boolean;
    showConfettiFirework: boolean;
    unknownCustomersSettings?: { playsPerDay: number };
    prizeWalletEnabled?: boolean;
    isNewsletterSubscriptionEnabled?: boolean;
    disablePrizeRedemptionEmails?: boolean;
    showAdAfterWin?: boolean;
}

export class PennySlotMachineGameDataFactory {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    static getInitial(campaignId: string, languages: CampaignLanguage[], templateConfig: any, assets: Asset[]): PennySlotMachineGameData {
        return {
            campaignId,
            speed: 1,
            multiRegistration: false,
            playsPerDay: 1,
            playLimitEnabled: true,
            numberOfIpPlaysLimit: 20,
            playMinutesLimit: 5,
            showWinPageAfter: 2,
            surveyEnabled: true,
            showSurveyAfter: 4,
            showIncompleteSurveyAfter: 7,
            showConfettiSnow: false,
            showConfettiFirework: false,
            prizeWalletEnabled: false,
            isNewsletterSubscriptionEnabled: false,
            disablePrizeRedemptionEmails: false,
            showAdAfterWin: false,
        };
    }
}
