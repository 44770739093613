import {Component, EventEmitter, Output} from "@angular/core";

@Component({
    selector: "app-search-input",
    templateUrl: "./search-input.component.html",
    styleUrls: ["./search-input.component.scss"],
})
export class SearchInputComponent {
    @Output() getSearchTerm = new EventEmitter<string>();

    searchTerm: string;
    constructor() { }

    search() {
        this.getSearchTerm.next(this.searchTerm);
    }

    clear() {
        this.searchTerm = "";
        this.getSearchTerm.next(this.searchTerm);
    }
}
