export const environment = {
    production: true,
    stage: 'stage',

    versionPollingInterval: 1000 * 60, // minute

    apiUrlPrefix: "https://stage-api.brame-gamification.com",
    keyCloakApiUrlPrefix: "https://stage-api.brame-gamification.com/keycloak",
    campaignDataUrlPrefix: "https://stage-api.brame-gamification.com/campaigns-data",
    buildServiceApiUrlPrefix: "https://stage-api.brame-gamification.com/build",
    analyticsEndpoint: "analytics.brame-gamification.com",
    resourceServiceApiUrlPrefix: "https://stage-api.brame-gamification.com/campaign-resource",

    serverlessApiBaseUrl: "https://stage-serverless-api.brame-gamification.com",
    surveyApiUrlPrefix: "https://stage-serverless-api.brame-gamification.com/survey-game/api",
    memoryApiBaseUrl: "https://stage-serverless-api.brame-gamification.com/memory-game",
    scratchCardApiBaseUrl: "https://stage-serverless-api.brame-gamification.com/scratch-card",
    pennySpinTheWheelApiBaseUrl: "https://stage-serverless-api.brame-gamification.com/penny-stw",
    advancedSpinTheWheelApiBaseUrl: "https://stage-serverless-api.brame-gamification.com/advanced-spin-the-wheel",
    prizeManagementApiBaseUrl: "https://stage-serverless-api.brame-gamification.com/prize-management",
    pennyPathApiBaseUrl: "https://test-serverless-api.brame-gamification.com/penny-path-v2",
    pennySlotMachineApiBaseUrl: "https://stage-serverless-api.brame-gamification.com/penny-slot-machine", // todo check if exists
    quizApiBaseUrl: "https://stage-serverless-api.brame-gamification.com/quiz",
    timeZonesApiBaseUrl: "https://stage-serverless-api.brame-gamification.com/time-zone",
    eventsApiBaseUrl: "https://stage-serverless-api.brame-gamification.com/events",

    campaignPreviewBaseUrl: "https://stage-preview.brame-gamification.com",
    campaignDataStorage: "https://stage-brame-campaign-data-storage.s3.amazonaws.com/campaign-data",

    pennyLeadsExportUrl: "https://wzffytc7lvlgud7arxbjsaqnkq0yjxxk.lambda-url.eu-central-1.on.aws",
    memoryGameLeadsExportUrl: "https://hrokvuhs5q5ntri7vlrgwoncqa0ajmgx.lambda-url.eu-central-1.on.aws",
    pennySlotMachineLeadsExportUrl: "https://ahcqxb6f6ods63oh36oskhakdq0fliyv.lambda-url.eu-central-1.on.aws",
    integrationAppAuthEndpoint: "https://stage-serverless-api.brame-gamification.com/integration-app",
    pennyPathLeadsExportUrl: "",

    integrationApp: {
        selfHosted: true,
        authEndpoint: "https://stage-serverless-api.brame-gamification.com/integration-app",
        approvedIntegrations: [
            { key: "salesforce", flowKey: "brame-lead-universal-flow" },
            { key: "hubspot", flowKey: "brame-lead-universal-flow" },
            { key: "mailchimp", flowKey: "brame-lead-universal-flow" },
            { key: "dynamics-crm", flowKey: "brame-lead-universal-flow" },
            { key: "salesforce-pardot", flowKey: "brame-lead-universal-flow" },
        ]
    },

    isDemoEnv: false,
    enableDemo:false,

    cookies: {
        brame_at: "brame_at",
        refresh_brame_at: "refresh_brame_at",
        session: "SESSION",
    },
    contextKeyName: "brame-context",
    // eslint-disable-next-line max-len
    fusionChartsKey: "4cE2orxG1H4G1C6C4D2E3F3A2C2I4B3C8D-16nf1D3D2iqH-7avB2C2eslD1H4B3B5A4D4A3E3C2A11A19B7C6D8D5B4F-10ffB2A5A7nA3C1E3cfqC5B7B2E-13yhF1C2F1suwC7E2B4D2C3A1A9A2A5E6F4hbbF2C8IE4rveE2F2E1C-22oC1F1G3D1eevB11B1E6F3E2B1E1B3E1A3D9C1A1G3l==",
    // Mixpanel
    mixpanelKey: "2a989804524f15cbdc8d6833074cbb4f",
    mixpanelProdEnv: false,
    backendAnalyticsEvents: {
        SUPPORTED_GAMES: ['quiz', 'memory-game', 'scratch-card', 'survey'],
        SUPPORTED_EVENTS: ["game_started", "game_finished", "lead_created"]
    }
};
