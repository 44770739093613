import {NgModule} from "@angular/core";
import {NgxImageCompressService} from "ngx-image-compress";
import {ContextService} from "./context.service";
import {MemoryService} from "./game-data/memory.service";
import {ConstantsService} from "./constants.service";

@NgModule({
    providers: [
        ContextService,
        MemoryService,
        ConstantsService,
        NgxImageCompressService,
    ],
})
export class ServicesModule {
}
