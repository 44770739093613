import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { NgxDropzoneModule } from "ngx-dropzone";
import { RouterModule } from "@angular/router";
import { ColorPickerModule } from "ngx-color-picker";
import { TranslateModule } from "@ngx-translate/core";
import { NgbDropdownModule, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ErrorTailorModule } from "@ngneat/error-tailor";
import { MDBBootstrapModule } from "angular-bootstrap-md";
import { PipesModule } from "src/app/core/pipes/pipes.module";
import {GooglePlaceModule} from "ngx-google-places-autocomplete";
import {AngularSvgIconModule} from "angular-svg-icon";
import {NgMultiSelectDropDownModule} from "ng-multiselect-dropdown";
import { DirectivesModule } from "../directives/directives.module";
import { TextInputComponent } from "./text-input/text-input.component";
import { FileInputComponent } from "./file-upload/file-upload.component";
import { SelectInputComponent } from "./select-input/select-input.component";
import { ColorPickerComponent } from "./color-picker/color-picker.component";
import { DatepickerInputComponent } from "./datepicker-input/datepicker-input.component";
import { SearchInputComponent } from "./search-input/search-input.component";
import { TextAreaInputComponent } from "./text-area-input/text-area-input.component";
import { CheckboxComponent } from "./checkbox/checkbox.component";
import { FontPickerComponent } from "./fontpicker-input/fontpicker-input.component";
import { ItemListComponent } from "./item-list/item-list.component";
import { NumberInputComponent } from "./number-input/number-input.component";
import { ClearAnalyticsConfirmationDialogComponent } from "./clear-analytics-confirmation/clear-analytics-confirmation.component";
import { ConfirmationDialogComponent } from "./confirmation-dialog/confirmation-dialog.component";
import { ErrorModalComponent } from "./error-dialog/error-modal.component";
import { EmailTemplateModalComponent } from "./email-template-modal/email-template-modal.component";
import { SelectItemModal } from "./select-item-modal/select-item-modal.component";
import { ToggleIconComponent } from "./toggle-icon/toggle-icon.component";
import { WarningModalComponent } from "./warning-dialog/warning-dialog.component";
import { AlertModalComponent } from "./alert-dialog/alert-dialog.component";
import { ChangeEmailModal } from "./change-email-modal/change-email-modal.component";
import { LanguagesDropdownComponent } from "./languages-dropdown/languages-dropdown.component";
import { MinValueDirective } from "./validators/minValue/min-value.directive";
import { MaxValueDirective } from "./validators/maxValue/max-value.directive";
import { IntegerValueDirective } from "./validators/integerValue/integer-value.directive";
import { DateRangeInputComponent } from "./date-range-input/date-range-input.component";
import {
    SimpleAnalyticsCardTitleDirective,
    SimpleAnalyticsCardComponent,
    SimpleAnalyticsCardContentDirective,
} from "./simple-analytics-card/simple-analytics-card.component";
import { ImgEnlargeOnHoverDirective } from "./img-enlarge-on-hover/img-enlarge-on-hover.directive";
import { CustomValidatorDirective } from "./validators/customValidator/custom-validator.directive";
import { MinTrimmedCharactersDirective } from "./validators/minTrimmedCharacters/min-trimmed-characters.directive";
import { MaxCharsDirective } from "./validators/maxChars/max-chars.directive";
import { EditToolbarCheckboxComponent } from "./edit-toolbar-checkbox/edit-toolbar-checkbox.component";
import { LanguagePopupComponent } from "./language-popup/language-popup.component";
import { RenderFailedModalComponent } from "./render-failed-modal/render-failed-modal.component";
import { CampaignLink } from "./campaign-link/campaign-link.component";
import {SwitchComponent} from "./switch/switch.component";
import {QuestionColorPickerComponent} from "./question-color-picker/question-color-picker.component";
import {QuestionPopupComponent} from "./question-popup/question-popup.component";
import {PaginationComponent} from "./pagination/pagination.component";
import {BigSwitchComponent} from "./big-switch/big-switch.component";
import {StepperComponent} from "./stepper/stepper.component";
import {InputComponent} from "./input/input.component";
import {StepButtonsComponent} from "./step-buttons/step-buttons.component";
import {ButtonComponent} from "./button/button.component";
import {InfoTooltipComponent} from "./info-tooltip/info-tooltip.component";
import {AssetLibraryPlaceholderComponent} from "./asset-library-placeholder/asset-library-placeholder.component";
import {PhoneInputComponent} from "./phone-input/phone-input.component";
import {AutoFocusDirective} from "./autofocus/autofocus.directive";
import {AddressInputComponent} from "./address-input/address-input.component";
import {TableComponent} from "./table/table.component";
import {LoaderComponent} from "./loader/loader.component";
import {ImageEditorComponent} from "./image-editor/image-editor.component";
import {MultiSelectComponent} from "./multi-select/multi-select.component";
import {CardComponent} from "./card/card.component";
import { AccordionComponent } from "./accordion/accordion.component";
import { AccordionItemComponent } from "./accordion/accordion-item/accordion-item.component";
import { CreateCampaignButtonComponent } from "./create-campaign-button/create-campaign-button.component";
import { DeleteLeadsConfirmationDialogComponent } from "./delete-leads-confirmation/delete-leads-confirmation.component";
import {NavbarComponent} from "./navbar/navbar.component";
import { AdventBannerComponent } from "./advent-banner/advent-banner.component";
import { UpcomingReleaseBannerComponent } from "./upcoming-release-banner/upcoming-release-banner.component";


const COMPONENTS = [
    NumberInputComponent,
    CheckboxComponent,
    TextInputComponent,
    FileInputComponent,
    SelectInputComponent,
    ColorPickerComponent,
    DatepickerInputComponent,
    SearchInputComponent,
    TextAreaInputComponent,
    FontPickerComponent,
    ItemListComponent,
    ClearAnalyticsConfirmationDialogComponent,
    ConfirmationDialogComponent,
    ErrorModalComponent,
    EmailTemplateModalComponent,
    SelectItemModal,
    ToggleIconComponent,
    WarningModalComponent,
    AlertModalComponent,
    ChangeEmailModal,
    LanguagesDropdownComponent,
    MinValueDirective,
    MaxValueDirective,
    IntegerValueDirective,
    DateRangeInputComponent,
    IntegerValueDirective,
    SimpleAnalyticsCardComponent,
    SimpleAnalyticsCardTitleDirective,
    SimpleAnalyticsCardContentDirective,
    ImgEnlargeOnHoverDirective,
    CustomValidatorDirective,
    MinTrimmedCharactersDirective,
    MaxCharsDirective,
    EditToolbarCheckboxComponent,
    LanguagePopupComponent,
    RenderFailedModalComponent,
    CampaignLink,
    SwitchComponent,
    QuestionColorPickerComponent,
    QuestionPopupComponent,
    PaginationComponent,
    BigSwitchComponent,
    StepperComponent,
    InputComponent,
    StepButtonsComponent,
    ButtonComponent,
    InfoTooltipComponent,
    AssetLibraryPlaceholderComponent,
    PhoneInputComponent,
    AutoFocusDirective,
    AddressInputComponent,
    TableComponent,
    LoaderComponent,
    ImageEditorComponent,
    MultiSelectComponent,
    CardComponent,
    AccordionComponent,
    AccordionItemComponent,
    CreateCampaignButtonComponent,
    DeleteLeadsConfirmationDialogComponent,
    NavbarComponent,
    AdventBannerComponent,
    UpcomingReleaseBannerComponent,
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        NgxDropzoneModule,
        ColorPickerModule,
        TranslateModule,
        NgbDropdownModule,
        ErrorTailorModule,
        MDBBootstrapModule.forRoot(),
        NgbModule,
        PipesModule,
        GooglePlaceModule,
        AngularSvgIconModule,
        NgMultiSelectDropDownModule,
        DirectivesModule,
    ],
    declarations: COMPONENTS,
    exports: [...COMPONENTS, PipesModule],
    entryComponents: [
        ConfirmationDialogComponent,
        ErrorModalComponent,
        EmailTemplateModalComponent,
        SelectItemModal,
        WarningModalComponent,
        AlertModalComponent,
        ChangeEmailModal,
        RenderFailedModalComponent,
    ],
})
export class ComponentsModule { }
