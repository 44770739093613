import {Injectable} from "@angular/core";
import mixpanel, {Dict} from "mixpanel-browser";
import {environment} from "../../../../environments/environment";

@Injectable({
    providedIn: "root",
})

export class MixpanelService {
    constructor() {
        if (environment.mixpanelKey) {
            mixpanel.init(environment.mixpanelKey);
        }
    }

    identify(id: string) {
        mixpanel.identify(id);
    }

    track(name: string, props?: Dict) {
        if (environment.mixpanelKey) {
            mixpanel.track(name, props);
        }
    }

    setPeople(props: Dict) {
        mixpanel.people.set(props);
    }

    triggerEvent(email: string, name: string, eventName: string) {
        if (email && environment.mixpanelKey) {
            this.identify(email);
            this.track(eventName, {
                debug: !environment.mixpanelProdEnv,
                Builder: "v1",
            });
            this.setPeople({$name: name, $email: email});
        }
    }
}
