import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { CampaignLanguage } from "@models/CampaignLanguage";
import { EmailSettings } from "@models/EmailSettings";
import { DeploymentStatus } from "../../models/DeploymentStatus";
import { DisplayType } from "../../models/DisplayType";
import { Page } from "../../models/Page";
import { LocalizationData } from "../../models/LocalizationData";

@Injectable()
export class BuilderStateService {
    $displayType: BehaviorSubject<DisplayType> = new BehaviorSubject<DisplayType>(DisplayType.DESKTOP);

    $selectedCampaignLanguage: BehaviorSubject<CampaignLanguage> = new BehaviorSubject(new CampaignLanguage());
    $selectedPage: BehaviorSubject<Page> = new BehaviorSubject(null);
    $demoLinkStatus: BehaviorSubject<DeploymentStatus> = new BehaviorSubject(DeploymentStatus.DOWN);

    $darkMode: BehaviorSubject<boolean> = new BehaviorSubject(false);

    /// todo delete
    $pageLoaded: BehaviorSubject<boolean> = new BehaviorSubject(false);
    $pageSettingsOpened: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    ///

    $selectedDynamicItem: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    $sideMenuOpened: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    $sideMenuItemOpened: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    $isSaving: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    /** autosave */
    $metadataModified: BehaviorSubject<boolean> = new BehaviorSubject(false);
    $configurationModified: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    $formSettingsModified: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false); // save config without debounceTime
    // some data like localization data or social buttons or some styles for email idk
    $someEmailDataModified: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    $pageModified: BehaviorSubject<Page> = new BehaviorSubject<Page>(null);
    $languagesModified: BehaviorSubject<LocalizationData> = new BehaviorSubject<LocalizationData>(null);
    $emailSettings = new BehaviorSubject<EmailSettings>(null);


    constructor() {}

    clear() {
        this.$displayType.next(DisplayType.DESKTOP);

        this.$selectedCampaignLanguage.next(new CampaignLanguage());
        this.$selectedPage.next(null);
        this.$demoLinkStatus.next(DeploymentStatus.DOWN);

        this.$darkMode.next(false);

        /// todo delete
        this.$pageLoaded.next(false);
        this.$pageSettingsOpened.next(false);
        ///

        this.$selectedDynamicItem.next(null);
        this.$sideMenuOpened.next(false);
        this.$sideMenuItemOpened.next(false);
        this.$isSaving.next(false);

        /** autosave */
        this.$metadataModified.next(false);
        this.$configurationModified.next(false);
        this.$someEmailDataModified.next(false); // some data like localization data or social buttons or some styles for email idk
        this.$pageModified.next(null);
        this.$languagesModified.next(null);
        this.$emailSettings.next(null);
    }

}
