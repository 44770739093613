import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "src/environments/environment";
import {PersonalityTestGameData, Question, PersonalityTestOutcome} from "@models/game-data/PersonalityTestGameData";
import {forkJoin} from "rxjs";
import {Outcome} from "@models/game-data/Outcome";

@Injectable({providedIn: "root"})
export class PersonalityTestService {

    apiPrefix = environment.apiUrlPrefix + "/personality-test";

    constructor(private http: HttpClient) {
    }

    /**
     * Creates complete game data
     * intended to be used only once, during campaign creation
     *
     * @param campaignId
     * @param data
     */
    createGameData(campaignId: string, data: PersonalityTestGameData) {
        return this.http.post(this.apiPrefix + `/game-settings/${campaignId}`, data)
            .pipe();
    }

    getGameData(campaignId: string) {
        return this.http.get(this.apiPrefix + `/game-settings/${campaignId}`)
            .pipe();
    }

    updateGameData(
        campaignId: string,
        data: PersonalityTestGameData,
        questionChanges: { created: any[]; deleted: any[]; updated: any[] }
    ) {
        /**
         * @updateData is QuizGameData without question and outcome arrays
         * @questionChanges is object with arrays of created , updated and deleted questions
         * so u know which request to send
         */
        const updateData = {...data};

        const r = this.http.put(this.apiPrefix + `/game-settings/${campaignId}`, updateData);
        const cr = questionChanges.created.map((q: Question) => this.createQuestion(campaignId, q));
        const dr = questionChanges.deleted.map((q: Question) => this.deleteQuestion(q));
        const ur = questionChanges.updated.map((q: Question) => this.updateQuestion(campaignId, q));

        return forkJoin([r, ...cr, ...dr, ...ur]);

    }

    createQuestion(campaignId: string, question: Question) {
        return this.http.post(this.apiPrefix + `/question/${campaignId}`, question);
    }

    updateQuestion(campaignId: string, question: Question) {
        return this.http.put(this.apiPrefix + `/question/${campaignId}`, question);
    }

    deleteQuestion(question: Question) {
        return this.http.delete(this.apiPrefix + `/question/${question.id}`);
    }

    createOutcome(outcome: PersonalityTestOutcome, campaignId: string) {
        const data = {
            ...outcome,
            id: undefined,
            outcomes: outcome.outcomes.map((el) => ({...el, id: undefined})),
        };

        return this.http.post(this.apiPrefix + `/outcome/${campaignId}`, data);
    }

    updateOutcome(outcome: Outcome, campaignId: string) {
        return this.http.put(this.apiPrefix + `/outcome/${campaignId}`, outcome);
    }

    deleteOutcome(outcomeId: string) {
        return this.http.delete(this.apiPrefix + `/outcome/${outcomeId}`);
    }
}
