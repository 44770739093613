import { Component, OnDestroy } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { delay, filter } from "rxjs/operators";
import { environment } from "src/environments/environment";
import {ScriptsService} from "@services/scripts/scripts.service";
import { AuthService } from "./core/auth/auth.service";
import { TutorialsNotificationPopupComponent } from "./core/components/tutorials-notification-popup/tutorials-notification-popup.component";
import { LanguageStorageService } from "./core/util-services/language-storage.service";
import { ModalService } from "./shared/modal/modal.service";

@Component({
    selector: "app-root",
    templateUrl: "app.component.html",
    styles: [],
})
export class AppComponent implements OnDestroy {
    title = "brame";

    private subscriptions: Subscription[] = [];

    constructor(
        private translate: TranslateService,
        private languageStorageService: LanguageStorageService,
        private auth: AuthService,
        private modals: ModalService,
        private scriptsService: ScriptsService,
    ) {
        translate.setDefaultLang("en");
        translate.addLangs(["en", "sr"]);
        const lang = this.languageStorageService.getLanguage();
        translate.use(lang);
        this.scriptsService.loadServiceWidget();

        this.subscriptions.push(
            this.auth.isFirstLogin$
                .pipe(
                    filter((isFirstLogin) => !!isFirstLogin),
                    delay(1500)
                )
                .subscribe(this.onFirstLogin.bind(this))
        );
    }

    ngOnDestroy() {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    private onFirstLogin() {
        if (environment.isDemoEnv) {
            this.modals.open(
                TutorialsNotificationPopupComponent.dialogName,
                TutorialsNotificationPopupComponent
            );
        }
    }
}
