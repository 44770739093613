<div class="wrapper {{ disabled ? 'disabled' : ''}}" *ngIf="!mini">

    <label class="label"> {{labelText}} </label>

    <div class="input" style="padding-top: 0.7rem;" (click)="toggleDropdownMenu()">
        {{ (showField && selectedItem) ?  (selectedItem[showField] | trimWord:maxWordLength)  :  selectedItem | trimWord:maxWordLength }}
    </div>

    <div *ngIf="dropdownOpened && !disabled" class="app-select-menu app-select-menu-height">
        <div *ngFor="let o of options" (click)="onItemSelected(o)" class="app-select-menu-item app-select-menu-height">
            {{ showField ? (o[showField] | trimWord:maxWordLength) : o | trimWord:maxWordLength }}
        </div>
    </div>

    <div class="toggle-btn" (click)="toggleDropdownMenu()">
        <img src="assets/builder/svg/arrow-down-icon.svg" alt="Arrow">
    </div>

</div>

<div *ngIf="mini" ngbDropdown class="mini-select-input" [class]="customClass">
    
    <button ngbDropdownToggle class="dropdown-item elipsis" [disabled]="disabled">
        {{ ((showField && selectedItem) ? selectedItem[showField] : selectedItem) }}
    </button>

    <div ngbDropdownMenu>
        <div class="app-select-menu-height">
            <button ngbDropdownItem (click)="onItemSelected(o)" *ngFor="let o of options">
                {{ showField ? o[showField] : o }} 
            </button>
        </div>
    </div>

</div>