import { CampaignLanguage } from "@models/CampaignLanguage";
import { Asset } from "@builder-models/Asset";
import { environment } from "src/environments/environment";

export interface PennyPathGameData {
    campaignId: string;
    noWinIcon: string;
    multiRegistration: boolean;
    playsPerDay: number;
    playLimitEnabled?: boolean;
    numberOfIpPlaysLimit?: number;
    playMinutesLimit?: number;
    showWinPageAfter: number;
    surveyEnabled: boolean;
    showSurveyAfter: number;
    showIncompleteSurveyAfter: number;
    showConfettiSnow: boolean;
    showConfettiFirework: boolean;
    language?: string;
    unknownCustomersSettings?: { playsPerDay: number };
    countryCode?: string;
    prizeWalletEnabled?: boolean;
    isNewsletterSubscriptionEnabled?: boolean;
    disablePrizeRedemptionEmails?: boolean;
    showAdAfterWin?: boolean;
}

export class PennyPathGameDataFactory {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    static getInitial(campaignId: string, languages: CampaignLanguage[], templateConfig: any, assets: Asset[]): PennyPathGameData {
        const gameData: PennyPathGameData = {
            campaignId,
            noWinIcon: PennyPathGameDataFactory.getNoWinIcon(campaignId, assets),
            multiRegistration: false,
            playsPerDay: 1,
            playLimitEnabled: true,
            numberOfIpPlaysLimit: 20,
            playMinutesLimit: 5,
            showWinPageAfter: 2,
            surveyEnabled: true,
            showSurveyAfter: 4,
            showIncompleteSurveyAfter: 7,
            showConfettiSnow: false,
            showConfettiFirework: false,
            prizeWalletEnabled: false,
            isNewsletterSubscriptionEnabled: false,
            disablePrizeRedemptionEmails: false,
            showAdAfterWin: false,
        };

        return gameData;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    static getNoWinIcon(campaignId: string, assets: Asset[]) {
        // TODO: extract from assets once it is available
        return `${environment.campaignPreviewBaseUrl}/${campaignId}/assets/images/awards/img-prize-0.png`;
    }
}
