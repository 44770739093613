import {Injectable} from "@angular/core";
import {CampaignType, PennyCampaign} from "@models/CampaignTypes";
import {LocalStorageKeys} from "@models/LocalStorageKeys";
import {TimeZone, TimeZoneChanges} from "@models/TimeZone";
import {environment} from "src/environments/environment";
import {Page} from "@builder-models/Page";
import {GAME_TYPE_ICONS, PENNY_API_BASE_URL_MAP, PENNY_LEADS_EXPORT_URL_MAP, WIZARD_GAME_TYPE_ICONS} from "../constants";

@Injectable()
export class UtilService {
    zip = (a: any[], b: string[]) => a.map((e, i) => [e, b[i]]);

    // compare arrays of items
    // find updated new and deleted items
    compareArrays = (oldArray: any[], newArray: any[], idField = "id") => {
        // hack - check for ids in arrays
        if (
            !this.checkForIds(oldArray, idField) ||
            !this.checkForIds(newArray, idField)
        ) {
            throw Error(
                "util.compareArraysError - Provide object that have an id field"
            );
        }

        const intersection = [];
        const u = [];
        const d = [];

        oldArray.forEach((x) => {
            let isIn = false;

            newArray.forEach((y) => {
                if (x[idField] !== y[idField]) {
                    return;
                }
                isIn = true;
                intersection.push(x);

                // TODO not fully tested
                const isUpdated = !this.compareObjects(x, y);

                if (isUpdated) {
                    u.push(y);
                }
            });

            if (!isIn) {
                d.push(x);
            }
        });

        const n = newArray.filter((el) => !intersection.map((el2) => el2[idField]).includes(el[idField]));
        return { created: n, updated: u, deleted: d };
    };

    /**
     * false - not same, true - same
     */
    compareObjects = (oldObject: any, newObject: any) => {
        const oldKeys = Object.keys(oldObject);

        let same = true;

        oldKeys.forEach((key) => {
            // if is array
            if (Array.isArray(oldObject[key])) {
                // if lenght differ they are not the same
                if (oldObject[key].length !== newObject[key].length) {
                    same = false;
                } else {
                    if (!(oldObject[key][0] instanceof Object)) {
                        oldObject[key].forEach((el, idx) => {
                            if (el !== oldObject[key][idx]) {
                                same = false;
                            }
                        });
                    } else {
                        oldObject[key].forEach((el, idx) => {
                            if (!this.compareObjects(el, newObject[key][idx])) {
                                same = false;
                            }
                        });
                    }
                }
            } else if (oldObject[key] instanceof Object) {
                if (!this.compareObjects(oldObject[key], newObject[key])) {
                    same = false;
                }
            } else if (
                !Array.isArray(oldObject[key]) ||
                !(oldObject[key] instanceof Object)
            ) {
                if (oldObject[key] !== newObject[key]) {
                    // if the field changed
                    same = false; // mark as updated and keep changes
                }
            }
        });

        return same;
    };
    /**
     * Deep copy of object
     *
     * @param stuff
     */
    copy = (stuff) => JSON.parse(JSON.stringify(stuff));

    toBase64 = (file: File): Promise<any> =>
        new Promise((resolve, reject) => {
            try {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = (error) => reject(error);
            } catch (e) {
                reject(e);
            }
        });

    parseFonts = (object: any) => {
        const result: any = {};
        Object.keys(object).map((key) => {
            result[key] = object[key];
            return result;
        });
        const position = result.id.lastIndexOf(".");
        result.key = result.id.substring(0, position);

        return result;
    };

    updateArray(array: any[], object: any, idField = "id") {
        const idx = array.findIndex((el) => object[idField] === el[idField]);

        if (idx === -1) {
            throw new Error("util - update in array: index not found");
        }

        array[idx] = object;
        return array[idx];
    }

    removeFromArray(array: any[], value: any, idField = "id") {
        const idx = array.findIndex((el) => el[idField] === value);

        if (idx === -1) {
            throw new Error("util - remove from array: index not found");
        }

        return array.splice(idx, 1);
    }

    arrayMove(arr: any[], from: number, to: number) {
        const element = arr[from];
        arr.splice(from, 1);
        arr.splice(to, 0, element);
    }

    calculatePercentageDiff(a: number, b: number): number {
        const percentage = a - b === 0 ? 0 : 100 * Math.abs((a - b) / b);
        return parseInt(percentage.toFixed(0));
    }

    // Remove duplicate objects from array of objects
    removeDuplicateObjects(arr: any[], key: string): any[] {
        return [...new Map(arr.map((item) => [item[key], item])).values()];
    }

    getRange(min: number, max: number): number[] {
        return [...Array(max - min + 1).keys()].map((i) => i + min);
    }

    getMinMax(value1: number, value2: number) {
        const max = value1 > value2 ? value1 : value2;
        const min = value1 < value2 ? value1 : value2;
        return { max, min };
    }

    // PLAZKETOVE UTIL FUNKCIJE
    // TODO - move somewhere

    // hm
    generateFontFaceCss = (fontsArray) =>
        fontsArray
            .map(
                (element: { key: string; type: string; url: string }) =>
                    `@font-face {
            font-family: "${element.key}" ;
            src: url(${element.url});
        }`
            )
            .join("\n");


    formatFontValue(font: string) {
        let str = font.split(",")[0];
        str = str.toLowerCase();
        str = str.replace(/[^a-zA-Z ]/g, "");
        str = str.replace(/\s/g, "");
        return str;
    }

    // can stay here
    copyValueToClipboard = (val) => {
        const selBox = document.createElement("textarea");
        selBox.style.position = "fixed";
        selBox.style.left = "0";
        selBox.style.top = "0";
        selBox.style.opacity = "0";
        selBox.value = val;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand("copy");
        document.body.removeChild(selBox);
    };

    isBase64 = (str) => {
        if (typeof str !== "string" || str === "" || str.trim() === "") {
            return false;
        }
        try {
            return str.startsWith("data");
        } catch (err) {
            return false;
        }
    };

    // generate random string width length of stringLength
    generateRandomString = (stringLength) => {
        const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        let result = "";
        const charactersLength = characters.length;
        for (let i = 0; i < stringLength; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    };

    // return file type from base64 string
    getFileType = (base64) => base64.substring("data:".length, base64.indexOf(";base64"));

    getFileExtension = (filePath) => filePath.split(".").pop();

    isEmptyArray = (dataArray) => dataArray.length === 0;

    // CHANGE WITH NEW DASHBOARD DESIGN
    getCampaignTypeIcons(location: string) {
        if (location === "shared") {
            return GAME_TYPE_ICONS;
        }
        if (location === "wizard") {
            return WIZARD_GAME_TYPE_ICONS;
        }
    }

    /**
     * convert date object to iso string "YYYYY-MM-DD"
     */
    dateToString(date: Date) {
        return date.toISOString().substring(0, 10);
    }

    /**
     * Returns DD-MM-YYYY HH:MM
     *
     * @param date
     */
    dateStringToDateTime(date: string) {
        const dateTime = new Date(date);
        const minutes = String(dateTime.getMinutes()).length === 1 ? `0${dateTime.getMinutes()}` : dateTime.getMinutes();
        return `${dateTime.getDate()}.${dateTime.getMonth() + 1}.${dateTime.getFullYear()} ${dateTime.getHours()}:${minutes}`;
    }

    /**
     * Returns ddd MMM D YYYY
     *
     * @param date
     */
    dateToDateString(date: string) {
        return new Date(date).toDateString();
    }

    /**
     * Returns DD MM YYYY with delimiter
     *
     * @param timestamp
     * @param delimiter ex. (":", ".", "-")
     */
    timestampToString(timestamp: string, delimiter: string) {
        return this.dateToString(this.convertToLocalDateTime(timestamp)).split("-").reverse().join(delimiter);
    }

    shouldSetNoActivity(date: string): boolean {
        const epochDate = new Date(0).toISOString();
        const activityDate = new Date(date).toISOString();

        return activityDate === epochDate;
    }

    hoursToString(timestamp: string) {
        const dateTime = this.convertToLocalDateTime(timestamp);
        const hours = dateTime.getHours();
        if (hours < 10) {
            return `0${hours} h`;
        }

        return `${hours} h`;
    }

    parseLeadRegistrationDate(dateTimeString: string): string {
        if (!dateTimeString) {
            return "";
        }
        if (dateTimeString.endsWith("Z") || dateTimeString.includes("-")) {
            return this.dateStringToDateTime(
                this.convertToLocalDateTime(dateTimeString).toISOString()
            );
        }

        const [date, time] = dateTimeString.split(" ");
        const [day, month, year] = date.split("/").map((part) => +part);
        const [hours, minutes, seconds] = time.split(":").map((part) => +part);
        const utcTimestamp = Date.UTC(
            year,
            month - 1,
            day,
            hours,
            minutes,
            seconds
        );
        return this.dateStringToDateTime(
            this.convertToLocalDateTime(
                new Date(utcTimestamp).toISOString()
            ).toISOString()
        );
    }

    addDays(date: Date, days: number) {
        const result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }

    isUrlValid(url: string) {
        const regex = "^(?:http(s)?:\\/\\/)[\\w.-]+(?:\\.[\\w\\.-]+)+[\\w\\-\\._~:/?#[\\]@!\\$&'\\(\\)\\*\\+,;=.]+$";
        const match = RegExp(regex).exec(url);
        return !!match;
    }

    getUrlParam(urlString: string, param: string) {
        const url = new URL(urlString);
        return url.searchParams.get(param);
    }

    /**
     * At least 8 characters, one uppercase letter, and one special character
     *
     * @param pass
     */
    isPasswordValid(pass: string) {
        const regex = "^(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\\s).{8,}$";
        const match = RegExp(regex).exec(pass);
        return !!match;
    }

    isTSBPasswordValid(pass: string) {
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z0-9])(?!.*\s).{12,}$/;
        const match = RegExp(regex).exec(pass);
        return !!match;
    }

    isSocialMediaURLValid(value: string): boolean {
        return (
            !value ||
            value.startsWith("https://") ||
            value.startsWith("http://")
        );
    }


    private checkForIds = (array: any[], idField = "id") => array.length === array.map((el) => el[idField]).filter((el) => el !== undefined).length;

    replaceCampaignIdInAssetUrl(url, newCampaignId) {
        if (url === "") {
            return url;
        }

        const tokens = url.split("/");

        const idxs = [];
        tokens.forEach((el, idx) => {
            if (el.length === 36) {
                idxs.push(idx);
            }
        });

        const i = idxs
            .map((idx: number) => {
                if (tokens[idx - 1] && tokens[idx - 1] !== "campaign-data") {
                    return false;
                }

                return !(tokens[idx + 1] && tokens[idx + 1] !== "assets");
            })
            .findIndex((el) => el);

        if (i === -1) {
            return url;
        }

        const tokenIdx = idxs[i];

        tokens[tokenIdx] = newCampaignId;

        return tokens.join("/");
    }

    capitalize(s: string) {
        if (!s) {
            return "";
        }
        return s[0].toUpperCase() + s.slice(1).toLowerCase();
    }

    isEmailValid(email: string) {
        const regex = /\S+@\S+\.\S+/;
        const match = RegExp(regex).exec(email);
        return !!match;
    }

    /**
     * This function resembles lodash _.isEqual
     * compare object, arrays
     *
     * @param first
     * @param second
     */
    isEqual(first: any, second: any): boolean {
        if (first === second) {
            return true;
        }
        if ((first === undefined || second === undefined || first === null || second === null)
            && (first || second)) {
            return false;
        }
        const firstType = first?.constructor.name;
        const secondType = second?.constructor.name;
        if (firstType !== secondType) {
            return false;
        }
        if (firstType === "Array") {
            if (first.length !== second.length) {
                return false;
            }
            let equal = true;
            for (let i = 0; i < first.length; i++) {
                if (!this.isEqual(first[i], second[i])) {
                    equal = false;
                    break;
                }
            }
            return equal;
        }
        if (firstType === "Object" || typeof first === "object") {
            let equal = true;
            const fKeys = Object.keys(first);
            const sKeys = Object.keys(second);
            if (fKeys.length !== sKeys.length) {
                return false;
            }
            // eslint-disable-next-line @typescript-eslint/prefer-for-of
            for (let i = 0; i < fKeys.length; i++) {
                if (first[fKeys[i]] && second[fKeys[i]]) {
                    if (first[fKeys[i]] === second[fKeys[i]]) {
                        continue; // eslint-disable-line
                    }
                    if (first[fKeys[i]] && (first[fKeys[i]].constructor.name === "Array"
                        || first[fKeys[i]].constructor.name === "Object")) {
                        equal = this.isEqual(first[fKeys[i]], second[fKeys[i]]);
                        if (!equal) {
                            break;
                        }
                    } else if (first[fKeys[i]] !== second[fKeys[i]]) {
                        equal = false;
                        break;
                    }
                } else if ((first[fKeys[i]] && !second[fKeys[i]]) || (!first[fKeys[i]] && second[fKeys[i]])) {
                    equal = false;
                    break;
                }
            }
            return equal;
        }
        return first === second;
    }

    /**
     * This function groups an array of objects by a given value
     * It is called like this: this.groupBy(this.data, (v) => v.value);
     *
     * @param array
     * @param value
     */
    groupBy(array: any[], value: any) {
        return array.reduce((a, b) => ((a[value(b)] ||= []).push(b), a), {});
    }

    loadImage(source: string): Promise<HTMLImageElement> {
        const image = new Image();
        image.crossOrigin = "Anonymous";
        image.src = source;
        return new Promise((resolve) => {
            image.onload = () => resolve(image);
        });
    }

    resolveCampaignEndpoint(campaignType: CampaignType) {
        let endpoint: string;
        switch (campaignType) {
        case CampaignType.SURVEY:
            endpoint = `${environment.surveyApiUrlPrefix}/survey-play`;
            break;
        case CampaignType.MEMORY:
            endpoint = environment.memoryApiBaseUrl;
            break;
        case CampaignType.SCRATCH_CARD:
            endpoint = `${environment.scratchCardApiBaseUrl}/scratch-card-play`;
            break;
        case CampaignType.PENNY_SLOT_MACHINE:
        case CampaignType.PENNY_SPIN_THE_WHEEL:
        case CampaignType.PENNY_PATH:
        case CampaignType.BIPA_SPIN_THE_WHEEL:
        case CampaignType.ADVANCED_SPIN_THE_WHEEL:

            endpoint = environment.serverlessApiBaseUrl;
            break;
        case CampaignType.QUIZ:
            endpoint = environment.quizApiBaseUrl;
            break;
        default:
            endpoint = environment.apiUrlPrefix;
        }

        return endpoint;
    }

    getTimeZoneSettings(timestamp: number, timeZoneChanges: TimeZoneChanges) {
        if (timeZoneChanges.length === 1) {
            return timeZoneChanges[0];
        }

        return timeZoneChanges.find((settings, index) => {
            const next = timeZoneChanges[index + 1];

            if (!next) {
                return false;
            }

            return (
                settings.start * 1000 <= timestamp &&
                timestamp < next.start * 1000
            );
        });
    }

    getTimeZoneSettingsForLocalDate(
        date: Date,
        timeZoneChanges: TimeZoneChanges
    ) {
        if (timeZoneChanges.length === 1) {
            return timeZoneChanges[0];
        }

        const utc = new Date(
            Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
        );

        return timeZoneChanges.find((settings, index) => {
            const next = timeZoneChanges[index + 1];

            if (!next) {
                return false;
            }

            return settings.start * 1000 <= utc.getTime() - settings.offset * 1000
                && utc.getTime() - next.offset * 1000 < next.start * 1000;
        });
    }

    convertToLocalDateTime(datetime: string): Date {
        const date = new Date(datetime);

        const systemOffsetInSeconds = -1 * date.getTimezoneOffset() * 60;
        const timeZone: TimeZone | null = JSON.parse(localStorage.getItem(LocalStorageKeys.TIME_ZONE));
        const currentTimeZoneSettings = this.getTimeZoneSettings(date.getTime(), timeZone ? timeZone.changes : []);

        const timeZoneOffsetInSeconds = timeZone
            ? (currentTimeZoneSettings ? currentTimeZoneSettings.offset : +timeZone.gmtOffset)
            : systemOffsetInSeconds;

        date.setSeconds(date.getSeconds() + timeZoneOffsetInSeconds - systemOffsetInSeconds);

        return date;
    }

    convertToISODateTime(localDateTime: Date | string, changes: Array<{ offset: number; start: number }> | null = null): string {
        const date = new Date(localDateTime);
        const timeZone: TimeZone | null = JSON.parse(localStorage.getItem(LocalStorageKeys.TIME_ZONE));
        const timeZoneChanges = changes || (timeZone ? timeZone.changes : []);
        const currentTimeZoneSettings = this.getTimeZoneSettingsForLocalDate(date, timeZoneChanges);

        const timeZoneOffsetInSeconds = timeZone
            ? (currentTimeZoneSettings ? currentTimeZoneSettings.offset : +timeZone.gmtOffset)
            : 0;

        date.setSeconds(date.getSeconds() - timeZoneOffsetInSeconds);

        return date.toISOString();
    }

    isPennyCampaign(campaignType: CampaignType) {
        return [
            CampaignType.PENNY_SLOT_MACHINE,
            CampaignType.PENNY_SPIN_THE_WHEEL,
            CampaignType.PENNY_PATH,
        ].includes(campaignType);
    }

    isAdvancedSpinTheWheel(campaignType: CampaignType) {
        return [
            CampaignType.ADVANCED_SPIN_THE_WHEEL,
        ].includes(campaignType);
    }

    isSpecialLangPennyCampaign(campaignType: CampaignType) {
        return [
            CampaignType.PENNY_SLOT_MACHINE,
            CampaignType.PENNY_SPIN_THE_WHEEL,
        ].includes(campaignType);
    }


    // For penny
    hasDOIMail(pages: Page[]) {
        return pages.find((page) => page.key === "doi-email");
    }

    getTimeZoneChanges() {
        const timeZone: TimeZone | null = JSON.parse(localStorage.getItem(LocalStorageKeys.TIME_ZONE));

        return (timeZone && timeZone.changes) || [];
    }

    timeZoneChangesRange() {
        const now = new Date();

        const from = new Date(now);
        const to = new Date(now);

        from.setFullYear(from.getFullYear() - 5);
        to.setFullYear(to.getFullYear() + 5);

        return {
            from: Math.floor(from.getTime() / 1000),
            to: Math.floor(to.getTime() / 1000),
        };
    }

    getYearMonthDay(date: Date) {
        const year = date.getFullYear();
        const month = date.getMonth() + 1 <= 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
        const day = date.getDate() >= 1 && date.getDate() <= 9 ? `0${date.getDate()}` : date.getDate();
        return { year, month, day };
    }

    nYearsAgo(n: number) {
        const date = new Date();
        date.setFullYear(date.getFullYear() - n);
        return date;
    }

    resolvePennyApiBaseURL(campaignType: PennyCampaign | CampaignType.BIPA_SPIN_THE_WHEEL) {
        return PENNY_API_BASE_URL_MAP[campaignType];
    }

    resolvePennyLeadsExportUrl(campaignType: PennyCampaign | CampaignType.BIPA_SPIN_THE_WHEEL) {
        return PENNY_LEADS_EXPORT_URL_MAP[campaignType];
    }
}
